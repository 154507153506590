document.addEventListener('DOMContentLoaded', () => {
  const carousels = document.querySelectorAll('.carousel-text');

  if (carousels.length === 0) {
    console.error("No carousels found on the page.");
    return;
  }

  carousels.forEach((carousel) => {
    const slides = carousel.querySelectorAll('.slide');
    const leftArrow = carousel.querySelector('.arrow.left');
    const rightArrow = carousel.querySelector('.arrow.right');

    if (!leftArrow || !rightArrow || slides.length === 0) {
      console.error("Carousel elements missing or improperly structured.");
      return;
    }

    let currentIndex = 0;
    let autoplayInterval;

    // Function to update the visibility of slides with fade effect
    const updateSlides = () => {
      const slideWidth = slides[0].offsetWidth; // Get the width of a single slide
      const slidesContainer = carousel.querySelector('.slides');

      // First, remove the 'fade' class from all slides
      slides.forEach(slide => {
        slide.classList.remove('fade');
      });

      // Add the 'fade' class to the current slide to trigger the fade effect
      slides[currentIndex].classList.add('fade');

      // Apply the sliding effect using translateX
      slidesContainer.style.transform = `translateX(-${currentIndex * slideWidth}px)`;
    };

    // Function to start the autoplay
    const startAutoplay = () => {
      // Clear any existing interval first
      clearInterval(autoplayInterval);

      // Start a new autoplay interval
      autoplayInterval = setInterval(() => {
        currentIndex = (currentIndex + 1) % slides.length;
        updateSlides();
      }, 10000); // Change slide every 10 seconds
    };

    // Initially show the first slide and start autoplay
    updateSlides();
    startAutoplay();

    // Move to the previous slide
    leftArrow.addEventListener('click', () => {
      currentIndex = (currentIndex - 1 + slides.length) % slides.length;
      updateSlides();
      startAutoplay(); // Restart autoplay on manual navigation
    }, { passive: true });

    // Move to the next slide
    rightArrow.addEventListener('click', () => {
      currentIndex = (currentIndex + 1) % slides.length;
      updateSlides();
      startAutoplay(); // Restart autoplay on manual navigation
    }, { passive: true });

    // Optional: Pause autoplay on hover
    carousel.addEventListener('mouseover', () => {
      clearInterval(autoplayInterval); // Stop autoplay on hover
    });

    carousel.addEventListener('mouseout', () => {
      startAutoplay(); // Restart autoplay after hover
    });
  });
});